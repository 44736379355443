import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where, deleteDoc, doc, orderBy, updateDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './QuizList.css';

const QuizList = () => {
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState('');
  const [quizzes, setQuizzes] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();

  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchCategories();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedCategory) {
      fetchSubCategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedSubCategory) {
      fetchSubSubCategories();
    }
  }, [selectedSubCategory]);

  useEffect(() => {
    if (selectedSubSubCategory || selectedSubCategory || selectedCategory) {
      fetchQuizzes();
    }
  }, [selectedSubSubCategory, selectedSubCategory, selectedCategory, sortOrder]);

  const fetchSubjects = async () => {
    const subjectsRef = collection(db, 'subjects');
    const q = query(subjectsRef, orderBy('name', 'asc'));
    const querySnapshot = await getDocs(q);
    setSubjects([
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchCategories = async () => {
    if (selectedSubject === 'all') {
      setCategories([]);
      return;
    }
    const categoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories`);
    const q = query(categoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchSubCategories = async () => {
    if (selectedCategory === 'all') {
      setSubCategories([]);
      return;
    }
    const subCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories`);
    const q = query(subCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    setSubCategories([
      { id: 'all', name: 'All' },
      ...querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }))
    ]);
  };

  const fetchSubSubCategories = async () => {
    if (selectedSubCategory === 'all') {
      setSubSubCategories([]);
      return;
    }
    const subSubCategoriesRef = collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${selectedSubCategory}/3rdCategories`);
    const q = query(subSubCategoriesRef, orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subSubCategoriesData = querySnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
  
    // Only add the "All" option if there are actual sub-subcategories
    if (subSubCategoriesData.length > 0) {
      setSubSubCategories([{ id: 'all', name: 'All' }, ...subSubCategoriesData]);
    } else {
      setSubSubCategories([]);
    }
  };

  const fetchQuizzes = async () => {
    let q;
    if (selectedSubSubCategory === 'all') {
      const subSubCategorySnapshot = await getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${selectedSubCategory}/3rdCategories`), orderBy('createdAt', 'asc'));
      const subSubCategoryIds = subSubCategorySnapshot.docs.map(doc => doc.id);
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', subSubCategoryIds), orderBy('num', sortOrder));
    } else if (selectedSubCategory === 'all') {
      const subCategorySnapshot = await getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories`), orderBy('createdAt', 'asc'));
      const subCategoryIds = subCategorySnapshot.docs.map(doc => doc.id);
      const subSubCategoryPromises = subCategoryIds.map(subCategoryId =>
        getDocs(collection(db, `subjects/${selectedSubject}/1stCategories/${selectedCategory}/2ndCategories/${subCategoryId}/3rdCategories`), orderBy('createdAt', 'asc'))
      );
      const subSubCategorySnapshots = await Promise.all(subSubCategoryPromises);
      const subSubCategoryIds = subSubCategorySnapshots.flatMap(snapshot => snapshot.docs.map(doc => doc.id));
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', 'in', [...subCategoryIds, ...subSubCategoryIds]), orderBy('num', sortOrder));
    } else if (selectedCategory === 'all') {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), orderBy('num', sortOrder));
    } else {
      q = query(collection(db, `subjects/${selectedSubject}/quizzes`), where('category', '==', selectedSubSubCategory || selectedSubCategory || selectedCategory), orderBy('num', sortOrder));
    }
    const querySnapshot = await getDocs(q);
    const quizzesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setQuizzes(quizzesData);
  };

  const handleDelete = async (id) => {
    if (window.confirm("삭제하시겠습니까?")) {
      await deleteDoc(doc(db, `subjects/${selectedSubject}/quizzes`, id));
      fetchQuizzes();
    }
  };
  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem.name);
    if (menuItem.name === '문제 리스트') {
      navigate('/QuizList', {
        state: {
          // Pass any relevant state here
          selectedSubject,
          selectedCategory,
          selectedSubCategory,
          selectedSubSubCategory,
        },
      });
    }
  };
  const handleEdit = (quiz) => {
    navigate('/QuizEdit', {
      state: {
        quiz,
        subjectId: selectedSubject,
        selectedCategory,
        selectedSubCategory,
        selectedSubSubCategory,
      },
    });
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedQuizzes = Array.from(quizzes);
    const [movedQuiz] = reorderedQuizzes.splice(result.source.index, 1);
    reorderedQuizzes.splice(result.destination.index, 0, movedQuiz);

    // Update the num values based on the new order
    for (let i = 0; i < reorderedQuizzes.length; i++) {
      const quiz = reorderedQuizzes[i];
      if (quiz.num !== i + 1) {
        const quizRef = doc(db, `subjects/${selectedSubject}/quizzes`, quiz.id);
        await updateDoc(quizRef, { num: i + 1 });
      }
    }

    setQuizzes(reorderedQuizzes);
    fetchQuizzes();

  };


// When a new subject is selected
const handleSubjectSelect = (subjectId) => {
  setSelectedSubject(subjectId);
  setSelectedCategory('');
  setSelectedSubCategory('');
  setSelectedSubSubCategory('');
  setCategories([]); // Reset categories
  setSubCategories([]); // Reset subcategories
  setSubSubCategories([]); // Reset sub-subcategories
};

// When a new category is selected
const handleCategorySelect = (categoryId) => {
  setSelectedCategory(categoryId);
  setSelectedSubCategory('');
  setSelectedSubSubCategory('');
  setSubCategories([]); // Reset subcategories
  setSubSubCategories([]); // Reset sub-subcategories
};

// When a new subcategory is selected
const handleSubCategorySelect = (subCategoryId) => {
  setSelectedSubCategory(subCategoryId);
  setSelectedSubSubCategory('');
  setSubSubCategories([]); // Reset sub-subcategories
};
const areQuizzesSequential = () => {
  for (let i = 0; i < quizzes.length; i++) {
    if (quizzes[i].num !== i + 1) {
      return false;
    }
  }
  return true;
};

const reorderQuizzes = async () => {
  const sortedQuizzes = [...quizzes].sort((a, b) => a.num - b.num);
  let currentNum = 1;

  for (const quiz of sortedQuizzes) {
    if (quiz.num !== currentNum) {
      const quizRef = doc(db, `subjects/${selectedSubject}/quizzes`, quiz.id);
      await updateDoc(quizRef, { num: currentNum });
    }
    currentNum++;
  }

  fetchQuizzes(); // Refresh the list after reordering
};
// Update the button click handlers
return (
  <div className="quiz-list-container">
    <div className="quiz-list">
      <h1>문제 리스트</h1>
      <div className="navbar">
        {subjects.map(subject => (
          <button
            key={subject.id}
            className={`nav-item ${selectedSubject === subject.id ? 'selected' : ''}`}
            onClick={() => handleSubjectSelect(subject.id)}
          >
            {subject.name}
          </button>
        ))}
      </div>
      {selectedSubject && categories.length > 0 && (
        <div className="navbar">
          {categories.map(category => (
            <button
              key={category.id}
              className={`nav-item ${selectedCategory === category.id ? 'selected' : ''}`}
              onClick={() => handleCategorySelect(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
      )}

{selectedCategory && subCategories.length > 0 && (
  <>
    <div className="label">중간 카테고리 선택</div>
    <div className="navbar">
      {subCategories.map(category => (
        <button
          key={category.id}
          className={`nav-item ${selectedSubCategory === category.id ? 'selected' : ''}`}
          onClick={() => handleSubCategorySelect(category.id)}
        >
          {category.name}
        </button>
      ))}
    </div>
  </>
)}

      {selectedSubCategory && subSubCategories.length > 0 && (
        <>
          <div className="label">하위 카테고리 선택</div>
          <div className="navbar">
            {subSubCategories.map(category => (
              <button
                key={category.id}
                className={`nav-item ${selectedSubSubCategory === category.id ? 'selected' : ''}`}
                onClick={() => setSelectedSubSubCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </>
      )}



        <div className="sort-order">
          <label htmlFor="sortOrder">정렬:</label>
          <select id="sortOrder" value={sortOrder} onChange={handleSortOrderChange}>
            <option value="asc">오름차순</option>
            <option value="desc">내림차순</option>
          </select>
          <p></p>
          </div>
          {selectedSubject !== 'all' && selectedCategory !== 'all' && selectedSubCategory !== 'all' && selectedSubSubCategory !== 'all' && (
          <button
            onClick={reorderQuizzes}
            style={{
              backgroundColor: areQuizzesSequential() ? 'gray' : 'blue',
              color: 'white',
              cursor: areQuizzesSequential() ? 'not-allowed' : 'pointer'
            }}
            disabled={areQuizzesSequential()}
          >
            번호 재정렬
          </button>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="quizzes">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="quiz-items"
              >
                {quizzes.map((quiz, index) => (
                  <Draggable key={quiz.id} draggableId={quiz.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="quiz-item"
                      >
                        <div className="quiz-info">
                          <div className="quiz-number">{quiz.num.toString().padStart(3, '0')}</div>
                          <div className="quiz-question">{quiz.question.length > 60 ? `${quiz.question.substring(0, 62)}...` : quiz.question}</div>
                          <div className="quiz-category">{quiz.categoryPath}</div>
                          <div className="quiz-type">Type {quiz.type.toUpperCase()}</div>
                        </div>
                        <div className="quiz-actions">
                          <button onClick={() => handleEdit(quiz)}>수정</button>
                          <button onClick={() => handleDelete(quiz.id)}>삭제</button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default QuizList;
