import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Editor, EditorState, RichUtils, ContentState, Modifier, SelectionState } from 'draft-js';
import { db } from '../firebaseConfig';
import { doc, updateDoc, collection, getDocs, query, orderBy } from 'firebase/firestore';
import 'draft-js/dist/Draft.css';
import './QuizAdd.css';

const QuizEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();


  const { quiz, subjectId } = location.state || {};
  const [selectedSubject, setSelectedSubject] = useState(subjectId);
  

  const [subjects, setSubjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected1stCategory, setSelected1stCategory] = useState('');
  const [selected2ndCategory, setSelected2ndCategory] = useState('');
  const [selected3rdCategory, setSelected3rdCategory] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [subSubCategories, setSubSubCategories] = useState([]);
  const [quizType, setQuizType] = useState(quiz?.type || 'a');
  const [answer, setAnswer] = useState(quiz?.answer || '');
  const [tag, setTag] = useState(quiz?.tag || '');
  const [premise, setPremise] = useState(quiz?.premise || '');
  const [options, setOptions] = useState(quiz?.options || ['', '', '', '', '']);

  const [dTypeAnswers, setDTypeAnswers] = useState(quiz?.dTypeAnswers || []);
  const [num, setNum] = useState(quiz?.num || '');
  const [errors, setErrors] = useState({});
  const [selected1stCategoryName, setSelected1stCategoryName] = useState('');
  const [selected2ndCategoryName, setSelected2ndCategoryName] = useState('');
  const [selected3rdCategoryName, setSelected3rdCategoryName] = useState('');
  const [isCategoryEditable, setIsCategoryEditable] = useState(false);


  const [skipTag, setSkipTag] = useState(quiz?.tag === '');


  const createEditorState = (content, underlines) => {
    let contentState = ContentState.createFromText(content || '');
    let editorState = EditorState.createWithContent(contentState);
  
    if (underlines && Array.isArray(underlines) && underlines.length > 0) {
      const blocks = contentState.getBlocksAsArray();
      let cumulativeLength = 0;
      const blockRanges = blocks.map((block) => {
        const start = cumulativeLength;
        const length = block.getLength();
        cumulativeLength += length + 1; // +1 for the newline character
        return { block, start, end: cumulativeLength - 1 };
      });
  
      underlines.forEach((underline) => {
        if (
          underline?.start !== undefined &&
          underline?.end !== undefined &&
          underline.start < underline.end
        ) {
          // Adjust the underline range for each block
          blockRanges.forEach(({ block, start: blockStart, end: blockEnd }) => {
            const overlapStart = Math.max(underline.start, blockStart);
            const overlapEnd = Math.min(underline.end, blockEnd);
  
            if (overlapStart < overlapEnd) {
              const blockKey = block.getKey();
              const anchorOffset = overlapStart - blockStart;
              const focusOffset = overlapEnd - blockStart;
  
              const selectionState = SelectionState.createEmpty(blockKey).merge({
                anchorOffset,
                focusOffset,
              });
  
              contentState = Modifier.applyInlineStyle(
                contentState,
                selectionState,
                'UNDERLINE'
              );
            }
          });
        }
      });
  
      editorState = EditorState.createWithContent(contentState);
    }
  
    return editorState;
  };
  
  
  
  
  // Example usage in component
  const [questionEditorState, setQuestionEditorState] = useState(() => 
    createEditorState(quiz?.question, quiz?.underlineSelections?.question)
  );
  const [explanationEditorState, setExplanationEditorState] = useState(() => 
    createEditorState(quiz?.explanation, quiz?.underlineSelections?.explanation)
  );
  const [comparisonEditorState, setComparisonEditorState] = useState(() => 
    createEditorState(quiz?.comparison, quiz?.underlineSelections?.comparison)
  );
  
  useEffect(() => {
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (selectedSubject) {
      fetchCategories();
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selected1stCategory) {
      fetchSubCategories(selected1stCategory);
    }
  }, [selected1stCategory]);

  useEffect(() => {
    if (selected2ndCategory && selected1stCategory) {
      fetchSubSubCategories(selected2ndCategory);
    }
  }, [selected2ndCategory, selected1stCategory]);

  useEffect(() => {
    if (quiz) {
      populateFormForEditing(quiz);
    }
  }, [quiz]);

  const fetchSubjects = async () => {
    const querySnapshot = await getDocs(query(collection(db, 'subjects'), orderBy('name', 'asc')));
    const subjectsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      name: doc.data().name
    }));
    setSubjects(subjectsData);
  };

  const fetchCategories = async () => {
    const querySnapshot = await getDocs(query(collection(db, `subjects/${selectedSubject}/1stCategories`), orderBy('createdAt', 'asc')));
    const categoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setCategories(categoriesData);
  };

  const fetchSubCategories = async (categoryId) => {
    const q = query(collection(db, `subjects/${selectedSubject}/1stCategories/${categoryId}/2ndCategories`), orderBy('createdAt', 'asc'));
    const querySnapshot = await getDocs(q);
    const subCategoriesData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setSubCategories(subCategoriesData);
  };

// Update the fetchSubSubCategories function
const fetchSubSubCategories = async (categoryId) => {
  const q = query(
    collection(db, 
      `subjects/${selectedSubject}/1stCategories/${selected1stCategory}/2ndCategories/${categoryId}/3rdCategories`
    ), 
    orderBy('createdAt', 'asc')
  );
  const querySnapshot = await getDocs(q);
  const subSubCategoriesData = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  setSubSubCategories(subSubCategoriesData);
};

  const populateFormForEditing = (quiz) => {
    setSelectedSubject(quiz.subject);
  
    // Assuming that you have the category IDs stored in the quiz object
    setSelected1stCategory(quiz.selected1stCategory || '');
    setSelected2ndCategory(quiz.selected2ndCategory || '');
    setSelected3rdCategory(quiz.selected3rdCategory || '');
  
    // Set category names
    const categoryParts = quiz.categoryPath ? quiz.categoryPath.split(' - ') : [];
    setSelected1stCategoryName(categoryParts[0] || '');
    setSelected2ndCategoryName(categoryParts[1] || '');
    setSelected3rdCategoryName(categoryParts[2] || '');
  
    setQuizType(quiz.type);
    setAnswer(quiz.answer);
    setTag(quiz.tag);
    setPremise(quiz.premise);
    setOptions(quiz.options || ['', '', '', '', '']);
    setDTypeAnswers(quiz.dTypeAnswers || []);
    setNum(quiz.num);
    setSkipTag(quiz.tag === '');
  };

  const validateQuiz = () => {
    let isValid = true;
    let newErrors = {};

    // Add validation logic here

    setErrors(newErrors);
    return isValid;
  };

// Add these handler functions
const handleSubjectChange = (e) => {
  setSelectedSubject(e.target.value);
  // Reset all category related states
  setSelected1stCategory('');
  setSelected1stCategoryName('');
  setSelected2ndCategory('');
  setSelected2ndCategoryName('');
  setSelected3rdCategory('');
  setSelected3rdCategoryName('');
  setCategories([]);
  setSubCategories([]);
  setSubSubCategories([]);
};

const handleFirstCategoryChange = (e) => {
  const selectedOption = e.target.options[e.target.selectedIndex];
  setSelected1stCategory(e.target.value);
  setSelected1stCategoryName(selectedOption.text);
  setSelected2ndCategory('');
  setSelected2ndCategoryName('');
  setSelected3rdCategory('');
  setSelected3rdCategoryName('');
  setSubCategories([]);
  setSubSubCategories([]);
};

const handleSecondCategoryChange = (e) => {
  const selectedOption = e.target.options[e.target.selectedIndex];
  setSelected2ndCategory(e.target.value);
  setSelected2ndCategoryName(selectedOption.text);
  setSelected3rdCategory('');
  setSelected3rdCategoryName('');
  setSubSubCategories([]);
};

const handleThirdCategoryChange = (e) => {
  const selectedOption = e.target.options[e.target.selectedIndex];
  setSelected3rdCategory(e.target.value);
  setSelected3rdCategoryName(selectedOption.text);
};



const handleUpdateQuiz = async () => {
  if (!validateQuiz()) {
    return;
  }
  const categoryPathParts = [
    selected1stCategoryName,
    selected2ndCategoryName, 
    selected3rdCategoryName
  ].filter(Boolean);
  
  const categoryPath = categoryPathParts.length > 0 ? categoryPathParts.join(' - ') : '';
  const targetSubject = isCategoryEditable ? selectedSubject : subjectId;


  const categoryNames = [selected1stCategoryName, selected2ndCategoryName, selected3rdCategoryName]
    .filter(name => name && name !== ''); // Filter out undefined or empty strings
  // Ensure premise is not undefined
  const premiseValue = premise || '';
  const targetCategory = isCategoryEditable ? 
    (selected3rdCategory || selected2ndCategory || selected1stCategory) : 
    quiz.category;
  const quizRef = doc(db, `subjects/${targetSubject}/quizzes`, quiz.id);
  await updateDoc(quizRef, {
    question: questionEditorState.getCurrentContent().getPlainText(),
    answer: quizType === 'a' || quizType === 'b' ? answer === 'true' : answer,
    explanation: explanationEditorState.getCurrentContent().getPlainText(),
    comparison: comparisonEditorState.getCurrentContent().getPlainText(),
    tag: skipTag ? '' : tag,
    premise: premise || '',
    options,
    dTypeAnswers,
    category: targetCategory,
    categoryPath: categoryPath || '', // Use the constructed categoryPath
    type: quizType,
    num: parseInt(num),
    underlineSelections: {
      question: getUnderlines(questionEditorState),
      explanation: getUnderlines(explanationEditorState),
      comparison: getUnderlines(comparisonEditorState)
    },
    categoryPath
  });
  alert('문제가 성공적으로 수정되었습니다!');
  navigate(-1);
};

const handleCancel = () => {
  navigate(-1); // Go back to the previous page
};

  const handleEditorChange = (editorState, setEditorState) => {
    setEditorState(editorState);
  };

  const toggleUnderline = (editorState, setEditorState) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  };

  const getUnderlines = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();
    let underlines = [];
    let absoluteOffset = 0;
  
    blocks.forEach(block => {
      const text = block.getText();
      const charList = block.getCharacterList();
      let currentUnderline = null;
  
      charList.forEach((char, index) => {
        if (char.hasStyle('UNDERLINE')) {
          if (!currentUnderline) {
            currentUnderline = { start: absoluteOffset + index, text: text[index] };
          } else {
            currentUnderline.text += text[index];
          }
        } else if (currentUnderline) {
          currentUnderline.end = absoluteOffset + index;
          underlines.push(currentUnderline);
          currentUnderline = null;
        }
      });
  
      if (currentUnderline) {
        currentUnderline.end = absoluteOffset + text.length;
        underlines.push(currentUnderline);
      }
  
      absoluteOffset += text.length + 1;
    });
  
    return underlines;
  };
  

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };
  

  const handleDTypeAnswerChange = (index, field, value) => {
    const newDTypeAnswers = [...dTypeAnswers];
    newDTypeAnswers[index] = { ...newDTypeAnswers[index], [field]: value };
    setDTypeAnswers(newDTypeAnswers);
  };

  const setBlank = () => {
    const selectionState = questionEditorState.getSelection();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = questionEditorState.getCurrentContent().getPlainText().slice(start, end);

    if (start !== end) {
      const newDTypeAnswer = { start, end, text: selectedText };
      setDTypeAnswers([...dTypeAnswers, newDTypeAnswer]);

      const contentState = Modifier.replaceText(
        questionEditorState.getCurrentContent(),
        selectionState,
        '_'.repeat(end - start)
      );

      const newEditorState = EditorState.push(questionEditorState, contentState, 'insert-characters');
      setQuestionEditorState(newEditorState);
    }
  };

  const insertSpecialChar = (char) => {
    const editorState = questionEditorState;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const newContentState = Modifier.replaceText(
      contentState,
      selectionState,
      char
    );
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
    setQuestionEditorState(newEditorState);
  };

  return (
    <div className="quiz-management">
      <h1>문제 수정</h1>
      <div className="current-category-info">
      <p><strong>현재 과목:</strong> {subjects.find(sub => sub.id === subjectId)?.name || '선택되지 않음'}</p>
      <p><strong>현재 카테고리:</strong> {selected1stCategoryName} {selected2ndCategoryName && `> ${selected2ndCategoryName}`} {selected3rdCategoryName && `> ${selected3rdCategoryName}`}</p>
  <p><strong>기존 카테고리:</strong> {quiz.categoryPath || '없음'}</p>
</div>

<div className="category-edit-checkbox">
  <label>
    <input
      type="checkbox"
      checked={isCategoryEditable}
      onChange={(e) => setIsCategoryEditable(e.target.checked)}
      style={{ marginRight: '5px' }}
    />
    카테고리 수정
  </label>
</div>

<div className="form-group">
  <label>과목 선택</label>
  <select
    value={selectedSubject}
    onChange={handleSubjectChange}
    className={errors.selectedSubject ? 'error' : ''}
    disabled={!isCategoryEditable}
  >
    <option value="">과목 선택</option>
    {subjects.map((subject) => (
      <option key={subject.id} value={subject.id}>{subject.name}</option>
    ))}
  </select>
  {errors.selectedSubject && <span className="error-message">{errors.selectedSubject}</span>}
</div>

<div className="form-group">
  <label>1차 카테고리 선택</label>
  <select
    value={selected1stCategory}
    onChange={handleFirstCategoryChange}
    className={errors.selected1stCategory ? 'error' : ''}
    disabled={!isCategoryEditable}
  >
    <option value="">카테고리 선택</option>
    {categories.map((category) => (
      <option key={category.id} value={category.id}>{category.name}</option>
    ))}
  </select>
  {errors.selected1stCategory && <span className="error-message">{errors.selected1stCategory}</span>}
</div>

{subCategories.length > 0 && (
  <div className="form-group">
    <label>2차 카테고리 선택</label>
    <select
      value={selected2ndCategory}
      onChange={handleSecondCategoryChange}
      className={errors.selected2ndCategory ? 'error' : ''}
      disabled={!isCategoryEditable}
    >
      <option value="">카테고리 선택</option>
      {subCategories.map((category) => (
        <option key={category.id} value={category.id}>{category.name}</option>
      ))}
    </select>
    {errors.selected2ndCategory && <span className="error-message">{errors.selected2ndCategory}</span>}
  </div>
)}

{subSubCategories.length > 0 && (
  <div className="form-group">
    <label>3차 카테고리 선택</label>
    <select
      value={selected3rdCategory}
      onChange={handleThirdCategoryChange}
      className={errors.selected3rdCategory ? 'error' : ''}
      disabled={!isCategoryEditable}
    >
      <option value="">카테고리 선택</option>
      {subSubCategories.map((category) => (
        <option key={category.id} value={category.id}>{category.name}</option>
      ))}
    </select>
    {errors.selected3rdCategory && <span className="error-message">{errors.selected3rdCategory}</span>}
  </div>
)}
      <div className="form-group">
        <label>문제 유형</label>
        <select value={quizType} onChange={(e) => setQuizType(e.target.value)}>
          <option value="a">Type A (True/False)</option>
          <option value="b">Type B (Premise + True/False)</option>
          <option value="c">Type C (Multiple Choice)</option>
          <option value="d">Type D (Fill in the Blank)</option>
        </select>
      </div>
      <div className="form-group">
        <label>문제</label>
        <div className="editor-container">
          <Editor
            editorState={questionEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setQuestionEditorState)}
          />
          {quizType === 'd' && (
            <button onClick={setBlank}>빈칸설정</button>
          )}
          <button onClick={() => toggleUnderline(questionEditorState, setQuestionEditorState)}>밑줄 설정</button>
        </div>
      </div>
      {quizType === 'b' && (
        <div className="form-group">
          <label>기본 지문</label>
          <textarea
            className="content-display"
            value={premise}
            onChange={(e) => setPremise(e.target.value)}
          />
        </div>
      )}
{quizType === 'c' && (
  <div className="form-group">
    <label>보기</label>
    {options.map((option, index) => (
      <textarea
        key={index}
        value={option}
        onChange={(e) => handleOptionChange(index, e.target.value)}
        placeholder={`Option ${index + 1}`}
      />
    ))}
  </div>
)}

      {quizType === 'd' && (
        <div className="form-group">
          <label>정답 (빈칸 채우기)</label>
          {dTypeAnswers.map((answer, index) => (
            <div key={index} className="d-type-answer">
              <input
                type="number"
                value={answer.start}
                onChange={(e) => handleDTypeAnswerChange(index, 'start', parseInt(e.target.value))}
                placeholder="Start"
              />
              <input
                type="number"
                value={answer.end}
                onChange={(e) => handleDTypeAnswerChange(index, 'end', parseInt(e.target.value))}
                placeholder="End"
              />
              <textarea
                className="content-display"
                value={answer.text}
                onChange={(e) => handleDTypeAnswerChange(index, 'text', e.target.value)}
                placeholder="Text"
              />
            </div>
          ))}
          <button onClick={() => setDTypeAnswers([...dTypeAnswers, { start: '', end: '', text: '' }])}>
            빈칸 추가
          </button>
        </div>
      )}
      <div className="form-group">
        <label>정답</label>
        {quizType === 'a' || quizType === 'b' ? (
          <div className="true-false-buttons">
            <button
              className={`true-false-button ${answer === true ? 'selected' : ''}`}
              onClick={() => setAnswer(true)}
            >
              O
            </button>
            <button
              className={`true-false-button ${answer === false ? 'selected' : ''}`}
              onClick={() => setAnswer(false)}
            >
              X
            </button>
          </div>
        ) : quizType === 'c' ? (
          <select value={answer} onChange={(e) => setAnswer(e.target.value)}>
            <option value="">정답 선택</option>
            {options.map((_, index) => (
              <option key={index} value={index + 1}>{index + 1}</option>
            ))}
          </select>
        ) : null}
      </div>
      <div className="form-group">
        <label>설명</label>
        <div className="editor-container">
          <Editor
            editorState={explanationEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setExplanationEditorState)}
          />
          <button onClick={() => toggleUnderline(explanationEditorState, setExplanationEditorState)}>밑줄 설정</button>
        </div>
      </div>
      <div className="form-group">
        <label>비교 (선택사항)</label>
        <div className="editor-container">
          <Editor
            editorState={comparisonEditorState}
            onChange={(editorState) => handleEditorChange(editorState, setComparisonEditorState)}
          />
          <button onClick={() => toggleUnderline(comparisonEditorState, setComparisonEditorState)}>밑줄 설정</button>
        </div>
      </div>
      <div className="form-group">
        <label>태그 (선택사항)</label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            disabled={skipTag}
            style={{ marginRight: '20px'}}
          />
          <label style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginRight: '10px', marginTop: '5px' }}>
            <input
              type="checkbox"
              checked={skipTag}
              onChange={(e) => setSkipTag(e.target.checked)}
              style={{ marginRight: '5px' }}
            />
            공란
          </label>
        </div>
      </div>
      <div className="form-group">
        <label>문제 번호</label>
        <input
          type="number"
          value={num}
          onChange={(e) => setNum(e.target.value)}
        />
      </div>
      <div className="special-char-buttons">
        {['①', '②', '③', '④', '⑤', '甲', '乙', '丙', '丁', '·', '『', '』', '「', '」'].map((char) => (
          <button
            key={char}
            className="special-char-button"
            data-char={char}
            onClick={() => insertSpecialChar(char)}
          >
            {char}
          </button>
        ))}
      </div>
      <div className="button-container">
      <button onClick={handleCancel}>취소</button>
      <button onClick={handleUpdateQuiz}>수정</button>
      </div>

    </div>
  );
};

export default QuizEdit;

