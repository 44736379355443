import React, { useState, useEffect } from 'react';

import './Authority.css';
import patentByNumber from '../data/patent_by_number.json';
import patentByGroup from '../data/patent_by_group.json';
import trademarkByNumber from '../data/trademark_by_number.json';
import trademarkByGroup from '../data/trademark_by_group.json';
import designByNumber from '../data/design_by_number.json';
import designByGroup from '../data/design_by_group.json';
import patentLaw from '../data/patentLaw.json';
import trademarkLaw from '../data/trademarkLaw.json';
import designLaw from '../data/designLaw.json';
import { useDebounce } from './useDebounce';
import { createExactMatcher, highlightMatch } from './choseongSearch';

const Authority = () => {
  const [selectedView, setSelectedView] = useState('byArticle');
  const [selectedSubject, setSelectedSubject] = useState('patent');
  const [modalContent, setModalContent] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [comparisonContent, setComparisonContent] = useState(null);
  const [expandedContent, setExpandedContent] = useState(null);
  const [originalContent, setOriginalContent] = useState(null);
  const [referencedArticle, setReferencedArticle] = useState(null);
  const [referencedModals, setReferencedModals] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(true);
  // Authority.js 상단에 state 추가
const [showScrollTop, setShowScrollTop] = useState(false);

// useEffect 추가
useEffect(() => {
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setShowScrollTop(scrollTop > 200); // 200px 이상 스크롤 시 버튼 표시
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, []);

// 스크롤 핸들러 함수 추가
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

// return 문 안에 버튼 추가 (authority-container 닫는 태그 바로 앞)
{showScrollTop && (
  <button 
    className="scroll-top-button"
    onClick={scrollToTop}
  >
    ↑
  </button>
)}

  const highlightSearchTerm = (text, searchTerm) => {
    if (!searchTerm) return text;
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return parts.map((part, index) => 
      part.toLowerCase() === searchTerm.toLowerCase() 
        ? `<mark class="search-highlight">${part}</mark>` 
        : part
    ).join('');
  };
  // Add search handler and effect
const handleSearch = (event) => {
  setSearchTerm(event.target.value);
};

useEffect(() => {
  if (debouncedSearchTerm.trim() === '') {
    setSearchResults([]);
    return;
  }

  const originalLaw = getOriginalLaw();
  const searchTermNoSpace = debouncedSearchTerm.trim();
  const regex = createExactMatcher(searchTermNoSpace);
  
  const results = originalLaw.filter(item => {
    const fullContent = item.contents.join('\n');
    const labelMatch = regex.test(item.label);
    const titleMatch = regex.test(item.title);
    const contentMatch = regex.test(fullContent);
    
    return labelMatch || titleMatch || contentMatch;
  }).map(item => ({
    ...item,
    id: item.label,
    label: highlightMatch(item.label, regex),
    title: highlightMatch(item.title, regex),
    matchedContents: item.contents
      .filter(content => regex.test(content))
      .map(content => {
        // Find all matching segments using regex
        const segments = [];
        let match;
        const segmentRegex = new RegExp(`.{0,20}${regex.source}.{0,20}`, 'g');
        while ((match = segmentRegex.exec(content)) !== null) {
          if (!segments.includes(match[0])) {
            segments.push(match[0]);
          }
        }
        
        return segments.map(segment => {
          const prefix = content.indexOf(segment) > 0 ? '... ' : '';
          const suffix = content.indexOf(segment) + segment.length < content.length ? ' ...' : '';
          return highlightMatch(prefix + segment + suffix, regex);
        }).join('\n');
      })
  }));

  setSearchResults(results);
}, [debouncedSearchTerm, selectedSubject]);


// 원본 텍스트에서의 인덱스를 찾는 헬퍼 함수
const findOriginalIndex = (original, noSpace, matchIndex) => {
  let spaceCount = 0;
  let i = 0;
  
  while (i < original.length) {
    if (i - spaceCount === matchIndex) {
      return i;
    }
    if (/\s/.test(original[i])) {
      spaceCount++;
    }
    i++;
  }
  return i;
};


const scrollToArticle = (articleId) => {
  const element = document.getElementById(articleId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
    element.classList.add('highlight-article');
    
    // Highlight searched text
    const regex = createExactMatcher(searchTerm);
    const contentElements = element.querySelectorAll('.article-content p');
    contentElements.forEach(p => {
      const originalText = p.textContent;
      p.innerHTML = highlightMatch(originalText, regex);
    });

    // Remove highlights after delay
    setTimeout(() => {
      element.classList.remove('highlight-article');
      contentElements.forEach(p => {
        p.innerHTML = p.textContent;
      });
    }, 3000);
    
    setShowDropdown(false);
  }
};


  // Modify the click handler
  useEffect(() => {
    window.handleArticleClick = (articleLabel) => {
      const originalLaw = getOriginalLaw();
      const article = originalLaw.find(a => a.label === articleLabel);
      if (article) {
        // If clicked from main content, clear existing modals first
        if (!referencedModals.length) {
          setReferencedModals([article]);
        } else {
          // If clicked from within a modal, add to existing modals
          setReferencedModals([...referencedModals, article]);
        }
      }
    };
    return () => {
      delete window.handleArticleClick;
    };
  }, [selectedSubject, referencedModals]);
// Add referenced article modals render function
const renderReferencedModals = () => {
  return referencedModals.map((article, index) => (
    <div 
      key={index} 
      className="custom-modal-overlay referenced-modal"
      style={{ left: `${50 + (index * 40)}px`, top: `${50 + (index * 40)}px` }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="custom-modal">
        <button 
          className="close-modal2-button" 
          onClick={() => {
            const newModals = [...referencedModals];
            newModals.splice(index, 1);
            setReferencedModals(newModals);
          }}
        >×</button>
        <div className="modal-content">
          <div className="article-header">
            <h3>{article.label}</h3>
            <span className="article-title">{article.title}</span>
          </div>
          <div className="article-content original-content">
            {article.contents.map((content, idx) => (
              <p key={idx} className={getIndentClass(content)}>
                {highlightAuthorities(content)}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  ));
};

const renderReferencedArticlePanel = () => {
  if (!referencedArticle) return null;

  return (
    <div className="referenced-article-panel">
      <div className="article-header">
        <h3>{referencedArticle.label}</h3>
        <span className="article-title">{referencedArticle.title}</span>
        <button className="close-panel-button" onClick={() => setReferencedArticle(null)}>×</button>
      </div>
      <div className="article-content original-content">
        {referencedArticle.contents.map((content, idx) => (
          <p key={idx} className={getIndentClass(content)}>
            {highlightAuthorities(content)}
          </p>
        ))}
      </div>
    </div>
  );
};

  const data = {
    patent: {
      byNumber: patentByNumber,
      byGroup: patentByGroup
    },
    trademark: {
      byNumber: trademarkByNumber,
      byGroup: trademarkByGroup
    },
    design: {
      byNumber: designByNumber,
      byGroup: designByGroup
    }
  };



  const getOriginalLaw = () => {
    switch(selectedSubject) {
      case 'patent': return patentLaw;
      case 'trademark': return trademarkLaw;
      case 'design': return designLaw;
      default: return null;
    }
  };
  // Add original law modal render function
const renderOriginalLaw = () => {
  if (!originalContent) return null;
  
  return (
    <div className="custom-modal-overlay" onClick={() => setOriginalContent(null)}>
      <div className="custom-modal" onClick={e => e.stopPropagation()}>
        <button className="close-modal2-button" onClick={() => setOriginalContent(null)}>×</button>
        <div className="modal-content">
          {originalContent.map((article, index) => (
            <div key={index} className="original-article">
              <h3>{article.label}</h3>
              <h4>{article.title}</h4>
              {article.contents.map((content, idx) => (
  <p key={idx} className={getIndentClass(content)}>
    (content)
  </p>
))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
const renderOriginalView = () => {
  const originalLaw = getOriginalLaw();

  const handleOriginalView = (article) => {
    const comparison = findMatchingArticle2(article.title);
    setComparisonContent(comparison);
  };

  return (
    <>
      <div className="search-container2">
      <input 
  type="text" 
  placeholder="조문 검색..." 
  value={searchTerm}
  onChange={handleSearch}
  onFocus={() => setShowDropdown(true)}
  className="search-input2"
/>
        {searchResults.length > 0 && showDropdown && (
          <div className="search-dropdown2">
  {searchResults.map((result, index) => (
    <div 
      key={index} 
      className="search-result-item2"
      onClick={() => scrollToArticle(result.id)}
    >
      <div className="result-header">
        <span dangerouslySetInnerHTML={{ __html: result.label }}></span>
        {" "}
        <span dangerouslySetInnerHTML={{ __html: result.title }}></span>
      </div>
      {result.matchedContents && result.matchedContents.map((content, idx) => (
        <div key={idx} className="matched-content">
          <span dangerouslySetInnerHTML={{ __html: content }}></span>
        </div>
      ))}
    </div>
  ))}
</div>


        
        
        )}
      </div>
      <div className="article-container" data-view="original">
        {originalLaw.map((article, index) => {
          const comparison = article.title;
          const hasMatchingArticle = Object.entries(comparison)
            .filter(([key]) => key !== `${selectedSubject}Article`)
            .some(([_, article]) => article !== null);

          return (
            <div key={index} id={article.label} className="article-card">
              <div className="article-header">
                <h3>{article.label}</h3>
                <div className="title-row">
                  <span className="article-title">{article.title}</span>
                  {hasMatchingArticle && (
                    <button
                      className="compare-button identical"
                      onClick={() => handleOriginalView(article)}
                    >
                      타법과 비교
                    </button>
                  )}
                </div>
              </div>
              <div className="article-content original-content">
                {article.contents.map((content, idx) => {
                  const lines = content.split('\n');
                  return lines.map((line, lineIdx) => (
                    <p key={`${idx}-${lineIdx}`} className={getIndentClass(line)}>
                      {highlightAuthorities2(line)}
                    </p>
                  ));
                })}
              </div>
            </div>
          );
        })}
      </div>
      {renderComparisonModal()}
    </>
  );
};

const getIndentClass = (line) => {
  // ①~㉜로 시작하는 경우 한칸 들여쓰기
  if (/^\s*[①-㉜]/.test(line)) {
    return 'indent-1';
  }
  // 1~9로 시작하는 경우 두칸 들여쓰기
  if (/^\s*([1-9]|[1-9][0-9])\./.test(line)) {
    return 'indent-2';
  }
  
  // 특정 한글 문자로 시작하는 경우 세칸 들여쓰기
  if (/^\s*[가|나|다|라|마|바|사|아|자|차|카|타|파|하]\./.test(line)) {
    return 'indent-3';
  }
  return ''; // 그 외의 경우 들여쓰기 없음
};


  const areAnyAuthoritiesDifferent = (key, articles) => {
    const values = Object.values(articles)
      .filter(Boolean)
      .map(article => article.contents[key]?.[0])
      .filter(Boolean);
  
    for (let i = 0; i < values.length; i++) {
      for (let j = i + 1; j < values.length; j++) {
        if (!compareAuthorities(values[i], values[j])) {
          return true;  // Any difference found will trigger highlighting for all
        }
      }
    }
    return false;
  };
  const highlightAuthorities = (text) => {
    // Existing authority highlighting
    const terms = ['특허청장', '특허심판원장', '심사관', '심판관', '심판장'];
    let highlightedText = text;
    
    terms.forEach(term => {
      highlightedText = highlightedText.replace(
        new RegExp(term, 'g'), 
        `<span class="highlight-authority">${term}</span>`
      );
    });
  
    // Add article reference highlighting
    highlightedText = highlightedText.replace(
      /(제\d+조(?:의\d+)?)/g,
      `<span class="article-reference" onclick="window.handleArticleClick('$1')">${'$1'}</span>`
    );
  
    return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
  };
  const highlightAuthorities2 = (text) => {

  
    // Add article reference highlighting
    text = text.replace(
      /(제\d+조(?:의\d+)?)/g,
      `<span class="article-reference" onclick="window.handleArticleClick('$1')">${'$1'}</span>`
    );
  
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };
  const findMatchingArticle = (title) => {
    const patentArticle = data?.patent?.byNumber?.find(article => article.title === title) || null;
    const trademarkArticle = data?.trademark?.byNumber?.find(article => article.title === title) || null;
    const designArticle = data?.design?.byNumber?.find(article => article.title === title) || null;
    return { patentArticle, trademarkArticle, designArticle };
  };
  const findMatchingArticle2 = (title) => {
    const normalizeTitle = (str) => str.replace(/\s+/g, '');
    const searchTitle = normalizeTitle(title);
    
    const patentArticle = patentLaw.find(article => normalizeTitle(article.title) === searchTitle) || null;
    const trademarkArticle = trademarkLaw.find(article => normalizeTitle(article.title) === searchTitle) || null;
    const designArticle = designLaw.find(article => normalizeTitle(article.title) === searchTitle) || null;
    
    return { patentArticle, trademarkArticle, designArticle };
  };
  
  
  const compareAuthorities = (leftValue, rightValue) => {
    if (!leftValue || !rightValue) return false;
    const leftAuth = leftValue.split(',').map(a => a.trim()).sort().join(',');
    const rightAuth = rightValue.split(',').map(a => a.trim()).sort().join(',');
    return leftAuth === rightAuth;
  };

  const isContentIdentical = (articles) => {
    const currentArticle = articles[selectedSubject];
    if (!currentArticle) return false;

    const otherArticles = Object.entries(articles)
      .filter(([key]) => key !== selectedSubject)
      .map(([_, article]) => article)
      .filter(Boolean);

    return otherArticles.every(otherArticle => {
      const currentEntries = Object.entries(currentArticle.contents);
      const otherEntries = Object.entries(otherArticle.contents);
      
      if (currentEntries.length !== otherEntries.length) return false;
      
      return currentEntries.every(([key, value]) => {
        const otherValue = otherArticle.contents[key];
        return otherValue && compareAuthorities(value[0], otherValue[0]);
      });
    });
  };

  const renderModal = () => {
    if (!modalContent) return null;
    
    return (
      <div className="custom-modal-overlay" onClick={() => setModalContent(null)}>
        <div className="custom-modal" onClick={e => e.stopPropagation()}>
          <button className="close-modal2-button" onClick={() => setModalContent(null)}>×</button>
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-article-label">{modalContent.articleLabel}</span>
              <span className="modal-article-title">{modalContent.articleTitle}</span>
              <span className="modal-article-section">{modalContent.section}</span>
            </div>
            <div className="modal-body">
              <div className="modal-authorities">
                <h4>권한 주체</h4>
                {modalContent.authorities.split(',').map((authority, index) => (
                  <span key={index} className="authority-tag">{authority.trim()}</span>
                ))}
              </div>
              <div className="modal-text">
                <h4>조문 내용</h4>
                <p>{highlightAuthorities(modalContent.content)}</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderComparisonModal = () => {
    if (!comparisonContent) return null;
  
    if (selectedView === 'original') {
      const articles = comparisonContent;
      
      return (
        <div className="custom-modal-overlay" onClick={() => setComparisonContent(null)}>
          <div className="custom-modal comparison-modal" onClick={e => e.stopPropagation()}>
            <button className="close-modal2-button" onClick={() => setComparisonContent(null)}>×</button>
            <div className="modal-content">
              <div className="comparison-container">
              {Object.entries(articles).filter(([_, article]) => article).map(([law, article]) => (
  <div key={law} className="comparison-column">
    <h3>{law === 'patentArticle' ? '특허법' : 
         law === 'trademarkArticle' ? '상표법' : 
         law === 'designArticle' ? '디자인보호법' : ''}
    </h3>
    <div className="comparison-article">
      <div className="modal-header">
        <span className="modal-article-label">{article.label}</span>
        <span className="modal-article-title">{article.title}</span>
      </div>
      <div className="article-content original-content">
        {article.contents.map((content, idx) => (
          <p key={idx} className={getIndentClass(content)}>
            {highlightAuthorities(content)}
          </p>
        ))}
      </div>
    </div>
  </div>
))}

              </div>
            </div>
          </div>
        </div>
      );
    }
  
    const { patentArticle, trademarkArticle, designArticle } = comparisonContent;
    const articles = {
      patent: patentArticle,
      trademark: trademarkArticle,
      design: designArticle
    };
  
    const allSectionKeys = [...new Set(
      Object.values(articles)
        .filter(Boolean)
        .flatMap(article => Object.keys(article.contents))
    )].sort();
  
    const otherLaws = Object.keys(articles).filter(key => key !== selectedSubject);
  
    return (
      <div className="custom-modal-overlay" onClick={() => setComparisonContent(null)}>
        <div className="custom-modal comparison-modal" onClick={e => e.stopPropagation()}>
          <button 
            className="close-modal2-button" 
            onClick={() => {
              setComparisonContent(null);
              setExpandedContent(null);
            }}
          >
            ×
          </button>
          <div className="modal-content">
            <div className="comparison-container">
              <div className="comparison-column">
                <h3>{selectedSubject === 'patent' ? '특허법' : 
                     selectedSubject === 'trademark' ? '상표법' : '디자인보호법'}</h3>
                {articles[selectedSubject] && (
                  <div className="comparison-article">
                    <div className="modal-header">
                      <span className="modal-article-label">{articles[selectedSubject].label}</span>
                      <span className="modal-article-title">{articles[selectedSubject].title}</span>
                    </div>
                    <div className="article-content">
                      {allSectionKeys.map(key => {
                        const value = articles[selectedSubject].contents[key];
                        if (!value) return <div key={key} className="content-item empty"></div>;
                        
                        return (
                          <div key={key} className="content-item">
                            <span 
                              className="content-key"
                              onClick={() => setExpandedContent({
                                articleLabel: articles[selectedSubject].label,
                                articleTitle: articles[selectedSubject].title,
                                section: key,
                                authorities: value[0],
                                content: value[1]
                              })}
                            >
                              {key}
                            </span>
                            <span className={`content-value ${
                              areAnyAuthoritiesDifferent(key, articles) ? 'different-value' : ''
                            }`}>
                              {value[0]}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {otherLaws.map(law => (
                <div key={law} className="comparison-column">
                  <h3>{law === 'patent' ? '특허법' : 
                       law === 'trademark' ? '상표법' : '디자인보호법'}</h3>
                  {articles[law] && (
                    <div className="comparison-article">
                      <div className="modal-header">
                        <span className="modal-article-label">{articles[law].label}</span>
                        <span className="modal-article-title">{articles[law].title}</span>
                      </div>
                      <div className="article-content">
                        {allSectionKeys.map(key => {
                          const value = articles[law].contents[key];
                          if (!value) return <div key={key} className="content-item empty"></div>;
                          
                          return (
                            <div key={key} className="content-item">
                              <span 
                                className="content-key"
                                onClick={() => setExpandedContent({
                                  articleLabel: articles[law].label,
                                  articleTitle: articles[law].title,
                                  section: key,
                                  authorities: value[0],
                                  content: value[1]
                                })}
                              >
                                {key}
                              </span>
                              <span className={`content-value ${
                                articles[selectedSubject]?.contents?.[key] && 
                                !compareAuthorities(value[0], articles[selectedSubject].contents[key][0])
                                ? 'different-value' : ''
                              }`}>
                                {value[0]}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {expandedContent && (
              <div className="expanded-content">
                <div className="modal-header">
                  <span className="modal-article-label">{expandedContent.articleLabel}</span>
                  <span className="modal-article-title">{expandedContent.articleTitle}</span>
                  <span className="modal-article-section">{expandedContent.section}</span>
                </div>
                <div className="modal-body">
                  <div className="modal-authorities">
                    <h4>권한 주체</h4>
                    {expandedContent.authorities.split(',').map((authority, index) => (
                      <span key={index} className="authority-tag">{authority.trim()}</span>
                    ))}
                  </div>
                  <div className="modal-text">
                    <h4>조문 내용</h4>
                    <p>{highlightAuthorities(expandedContent.content)}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  const renderByArticle = () => {
    const currentData = data[selectedSubject].byNumber;
    
    return (
      <div className="article-container">
        {currentData.map((article, index) => {
          const comparison = findMatchingArticle(article.title);
          const hasMatchingArticle = Object.entries(comparison)
            .filter(([key]) => key !== `${selectedSubject}Article`)
            .some(([_, article]) => article !== null);
          
          const identical = hasMatchingArticle && isContentIdentical({
            patent: comparison.patentArticle,
            trademark: comparison.trademarkArticle,
            design: comparison.designArticle
          });

          return (
            <div key={index} className="article-card">
              <div className="article-header">
                <h3>{article.label}</h3>
                <div className="title-row">
                  <span className="article-title">{article.title}</span>
                  {hasMatchingArticle && (
                    <button 
                      className={`compare-button ${identical ? 'identical' : 'different'}`}
                      onClick={() => setComparisonContent(comparison)}
                    >
                      {`타법${identical ? '과 동일' : '과 비교'}`}
                    </button>
                  )}
                </div>
              </div>
              <div className="article-content">
                {Object.entries(article.contents).map(([key, value]) => (
                  <div key={key} className="content-item">
                    <span 
                      className="content-key"
                      onClick={() => setModalContent({
                        articleLabel: article.label,
                        articleTitle: article.title,
                        section: key,
                        authorities: value[0],
                        content: value[1]
                      })}
                    >
                      {key}
                    </span>
                    <span className="content-value">{value[0]}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
        {renderComparisonModal()}
      </div>
    );
  };

  const toggleGroup = (group) => {
    setExpandedGroups(prev => ({
      ...prev,
      [group]: !prev[group]
    }));
  };
  const renderByGroup = () => {
    const currentData = data[selectedSubject].byGroup;
    
    const getGroupCategory = (group) => {
      const authorities = group.split(',').map(a => a.trim());
      if (authorities.every(auth => ['특허청장', '심사관'].includes(auth))) {
        return 'patent-office';
      }
      if (authorities.every(auth => ['특허심판원장', '심판관', '심판장'].includes(auth))) {
        return 'trial-office';
      }
      return 'common';
    };
  
    return (
      <div className="group-container">
        <div className="group-category">
          <h2 className="category-title">특허청 업무</h2>
          {Object.entries(currentData)
            .filter(([group]) => getGroupCategory(group) === 'patent-office')
            .map(([group, articles]) => (
              <div key={group} className={`group-card patent-office-group ${expandedGroups[group] ? 'expanded' : ''}`}>
                <div className="group-header">
                  <h3 className="group-title">
                    {group}
                    <button 
                      className="toggle-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleGroup(group);
                      }}
                    >
                      {expandedGroups[group] ? '▼' : '▶'}
                    </button>
                  </h3>
                </div>
                {expandedGroups[group] && (
                  <div className="group-content">
                    {articles.map((article, index) => (
                      <div
                        key={index}
                        className="group-article-item"
                        onClick={() => setModalContent({
                          articleLabel: article[0],
                          articleTitle: article[1],
                          section: article[2][0],
                          authorities: group,
                          content: article[2][1]
                        })}
                      >
                        <div className="article-info">
                          <span className="article-label">{article[0]}</span>
                          <span className="article-section">{article[2][0]}</span>
                          <span className="article-name">{article[1]}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="group-category">
          <h2 className="category-title">공통 업무</h2>
          {Object.entries(currentData)
            .filter(([group]) => getGroupCategory(group) === 'common')
            .map(([group, articles]) => (
              <div key={group} className={`group-card common-group ${expandedGroups[group] ? 'expanded' : ''}`}>
                <div className="group-header">
                  <h3 className="group-title">
                    {group}
                    <button 
                      className="toggle-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleGroup(group);
                      }}
                    >
                      {expandedGroups[group] ? '▼' : '▶'}
                    </button>
                  </h3>
                </div>
                {expandedGroups[group] && (
                  <div className="group-content">
                    {articles.map((article, index) => (
                      <div
                        key={index}
                        className="group-article-item"
                        onClick={() => setModalContent({
                          articleLabel: article[0],
                          articleTitle: article[1],
                          section: article[2][0],
                          authorities: group,
                          content: article[2][1]
                        })}
                      >
                        <div className="article-info">
                          <span className="article-label">{article[0]}</span>
                          <span className="article-section">{article[2][0]}</span>
                          <span className="article-name">{article[1]}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="group-category">
          <h2 className="category-title">심판원 업무</h2>
          {Object.entries(currentData)
            .filter(([group]) => getGroupCategory(group) === 'trial-office')
            .map(([group, articles]) => (
              <div key={group} className={`group-card trial-office-group ${expandedGroups[group] ? 'expanded' : ''}`}>
                <div className="group-header">
                  <h3 className="group-title">
                    {group}
                    <button 
                      className="toggle-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleGroup(group);
                      }}
                    >
                      {expandedGroups[group] ? '▼' : '▶'}
                    </button>
                  </h3>
                </div>
                {expandedGroups[group] && (
                  <div className="group-content">
                    {articles.map((article, index) => (
                      <div
                        key={index}
                        className="group-article-item"
                        onClick={() => setModalContent({
                          articleLabel: article[0],
                          articleTitle: article[1],
                          section: article[2][0],
                          authorities: group,
                          content: article[2][1]
                        })}
                      >
                        <div className="article-info">
                          <span className="article-label">{article[0]}</span>
                          <span className="article-section">{article[2][0]}</span>
                          <span className="article-name">{article[1]}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
        </div>
  
       
      </div>
    );
  };
  

  return (
    <div className="authority-container">
      <div className="authority-header">
        <h2>법령 조문별 권한</h2>
        <div className="subject-controls">
          <button 
            className={`subject-button ${selectedSubject === 'patent' ? 'active' : ''}`}
            onClick={() => setSelectedSubject('patent')}
          >
            특허법
          </button>
          <button 
            className={`subject-button ${selectedSubject === 'trademark' ? 'active' : ''}`}
            onClick={() => setSelectedSubject('trademark')}
          >
            상표법
          </button>
          <button 
            className={`subject-button ${selectedSubject === 'design' ? 'active' : ''}`}
            onClick={() => setSelectedSubject('design')}
          >
            디자인보호법
          </button>
        </div>
        <div className="view-controls">
        <button 
    className={`view-button ${selectedView === 'original' ? 'active' : ''}`}
    onClick={() => setSelectedView('original')}
  >
    원문 보기
  </button>
  <button 
    className={`view-button ${selectedView === 'byArticle' ? 'active' : ''}`}
    onClick={() => setSelectedView('byArticle')}
  >
    조문별 주체
  </button>

  <button 
    className={`view-button ${selectedView === 'byGroup' ? 'active' : ''}`}
    onClick={() => setSelectedView('byGroup')}
  >
    주체그룹별
  </button>
</div>
      </div>
      

      {showScrollTop && (
      <button 
        className="scroll-top-button"
        onClick={scrollToTop}
        aria-label="맨 위로 이동"
      >
        ⬆
      </button>
    )}



      <div className="authority-content">
  {selectedView === 'byArticle' 
    ? renderByArticle() 
    : selectedView === 'original'
      ? renderOriginalView()
      : renderByGroup()}
</div>

      {renderModal()}
      {renderOriginalLaw()}
      {renderReferencedModals()}

    </div>
  );
};

export default Authority;
