// src/App.js
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { auth, db } from "./firebaseConfig";
import { setUser, clearUser } from "./store";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import Navbar from "./components/Navbar"; // 분리된 Navbar 컴포넌트 임포트
import Home from "./components/Home";
import Login from "./components/Login";
import Admin from "./components/Admin";
import CategoryManagement from "./components/CategoryManagement";
import QuizAdd from "./components/QuizAdd";
import UserManagement from "./components/UserManagement";
import NoticeManagement from "./components/NoticeManagement";
import QuizSelecting from "./components/QuizSelecting";
import QuizSolving from "./components/QuizSolving";
import QuizList from "./components/QuizList";
import QuizEdit from './components/QuizEdit';
import SavedQuizzes from './components/SavedQuizzes';
import FillCategoryPath from './components/FillCategoryPath'; // 새로 추가된 컴포넌트 임포트
import SubjectSelecting from './components/SubjectSelecting';
import StudyTracker from './components/StudyTracker';
import Authority from './components/Authority';

import axios from 'axios';
import QuizCreator from "./components/QuizCreator";
import QuizPlus from "./components/QuizPlus";
import Profile from './components/Profile';



// Define axiosInstance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5001',
  withCredentials: true
});


// Export axiosInstance
export { axiosInstance };

const App = () => {
  const dispatch = useDispatch();
  const [currentView, setCurrentView] = useState('home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [mainsubCategories, setMainsubCategories] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [isRandomOrder, setIsRandomOrder] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        dispatch(setUser(user));
        const userDoc = await getDoc(doc(db, "admin", user.uid));
        if (userDoc.exists() && userDoc.data().role === "admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } else {
        dispatch(clearUser());
        setIsAdmin(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  return (
    
    <Router>
      <Navbar
        currentView={currentView}
        setCurrentView={setCurrentView}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        mainsubCategories={mainsubCategories}
        setSelectedmainCategory={setSelectedMainCategory}
        // fetchSubCategories={fetchSubCategories}
        // fetchSavedQuizzes={fetchSavedQuizzes}
        // fetchUserProfile={fetchUserProfile}
        // toggleSettings={toggleSettings}
        isAdmin={isAdmin}
        // fetchQuizzes={fetchQuizzes}
        selectedMainCategory={selectedMainCategory}
        selectedSubCategory={selectedSubCategory}
        isRandomOrder={isRandomOrder}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/categoryManagement" element={<CategoryManagement />} />
        <Route path="/QuizAdd" element={<QuizAdd />} />
        <Route path="/QuizEdit" element={<QuizEdit />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/noticeManagement" element={<NoticeManagement />} />
        <Route path="/subject-selecting" element={<SubjectSelecting />} />
        <Route path="/study-tracker" element={<StudyTracker />} />
        <Route path="/authority" element={<Authority />} />


        <Route path="/quiz-selecting" element={<QuizSelecting />} />
        <Route path="/quiz-solving" element={<QuizSolving />} />
        <Route path="/quizList" element={<QuizList />} />
        <Route path="/quiz-edit" element={<QuizEdit />} />
        <Route path="/saved" element={<SavedQuizzes />} />
        <Route path="/fillCategoryPath" element={<FillCategoryPath />} /> {/* 새로 추가된 라우트 */}
        <Route path="/quiz-creator" element={<QuizCreator />} /> 
        <Route path="/quiz-plus" element={<QuizPlus />} /> 

        <Route path="/profile" element={<Profile />} />

      </Routes>
    </Router>
  );
};

export default App;
