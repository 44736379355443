import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc, setDoc, getDocs, collection } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import './StudyTracker.css';
import { v4 as uuidv4 } from 'uuid'; // Import a UUID library
import { ChromePicker } from 'react-color';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const StudyTracker = () => {
  const [courses, setCourses] = useState([]);
  const [newCourseName, setNewCourseName] = useState('');
  const [newCourseLectures, setNewCourseLectures] = useState('');
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [dragEnd, setDragEnd] = useState(null);
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [allUsersData, setAllUsersData] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const [userColors, setUserColors] = useState({});
  const predefinedColors = ['#FF6B6B', '#45B7D1', '#F7B267', '#5567FF']; // Add more colors as needed
  const [expandedCourses, setExpandedCourses] = useState({});
  const [showColorPicker, setShowColorPicker] = useState(null);
  const [showColorModal, setShowColorModal] = useState(false);
  const [tempColor, setTempColor] = useState(null);  // Add this line here
  const [personalOrder, setPersonalOrder] = useState({});
  const [showMemoModal, setShowMemoModal] = useState(false);
  const [selectedProgress, setSelectedProgress] = useState(null);
  const [memoText, setMemoText] = useState('');
  const [selectionStart, setSelectionStart] = useState(null);
  const [dDay, setDDay] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [tempDate, setTempDate] = useState('');

  // Add this function to load D-day from Firestore
  const loadDDay = async () => {
    const dDayDoc = await getDoc(doc(db, 'dDay', user.uid));
    if (dDayDoc.exists()) {
      setDDay(dDayDoc.data().date);
    }
  };
  
  // Add this function to save D-day to Firestore
  const saveDDay = async (date) => {
    await setDoc(doc(db, 'dDay', user.uid), { date });
    setDDay(date);
  };
  
  // Add this useEffect for D-day countdown
  useEffect(() => {
    if (dDay && !showMemoModal) {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = new Date(dDay).getTime() - now;
        
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');
        
        setTimeLeft(`D-${days} ${hours}:${minutes}:${seconds}`);
      }, 1000);
  
      return () => clearInterval(timer);
    }
  }, [dDay, showMemoModal]);
  
  
  
  // Add this to your initial useEffect
  useEffect(() => {
    if (user) {
      loadDDay();
      // ... existing code
    }
  }, [user]);
  const handleBlockClick = (courseCode, lectureIndex) => {
    if (selectionStart === null) {
      // First click - start selection
      setSelectionStart(lectureIndex);
    } else {
      // Second click - complete selection
      const start = Math.min(selectionStart, lectureIndex) + 1;
      const end = Math.max(selectionStart, lectureIndex) + 1;
      updateProgress(courseCode, start, end);
      setSelectionStart(null);
    }
  };

  
  const MemoModal = ({ progress, onClose, onSave, onDelete }) => {
    const [memo, setMemo] = useState(progress.memo || '');
    
    return (
      <div className="memo-modal-overlay">
        <div className="memo-modal">
          <h3>메모</h3>
          <textarea
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder="Add your memo here..."
          />
          <div className="memo-buttons">
            <button onClick={() => onSave(memo)}>Save</button>
            {progress.memo && <button onClick={onDelete}>Delete</button>}
            <button onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };
  const handleProgressClick = (progress) => {
    if (progress.updater === user.uid) {
      setSelectedProgress(progress);
      setMemoText(progress.memo || '');
      setShowMemoModal(true);
    }
  };
  
  
  const saveMemo = async (memo) => {
    const updatedAllUsersData = allUsersData.map(userData => ({
      ...userData,
      courses: userData.courses.map(course => ({
        ...course,
        progress: course.progress.map(p => 
          p.id === selectedProgress.id ? {...p, memo} : p
        )
      }))
    }));
  
    setAllUsersData(updatedAllUsersData);
    await saveUserData(user.uid, updatedAllUsersData.find(d => d.userId === user.uid).courses);
    setShowMemoModal(false);
  };
  
  const deleteMemo = async () => {
    await saveMemo('');
  };
  
  const loadPersonalOrder = async () => {
    const orderDoc = await getDoc(doc(db, 'courseOrder', user.uid));
    if (orderDoc.exists()) {
      setPersonalOrder(orderDoc.data().order || {});
    }
  };
  
  useEffect(() => {
    if (user) {
      loadPersonalOrder();
    }
  }, [user]);
  
  // Save personal order
  const savePersonalOrder = async (orderData) => {
    await setDoc(doc(db, 'courseOrder', user.uid), { order: orderData });
  };

  useEffect(() => {
    if (user) {
      const initializeUserColor = async () => {
        const userColorDoc = await getDoc(doc(db, 'userColors', user.uid));
        if (!userColorDoc.exists()) {
          setShowColorModal(true);
        }
      };
  
      // Initial loads
      const initialLoad = async () => {
        await Promise.all([
          loadUserColors(),
          loadUserData(user.uid),
          loadAllUsersData(),
          initializeUserColor()
        ]);
      };
      
      initialLoad();
  
      // Set interval only when modals are closed
      let intervalId;
      if (!showMemoModal && !showColorModal) {
        intervalId = setInterval(loadAllUsersData, 10000);
      }
  
      // Cleanup function
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }
  }, [user, showMemoModal, showColorModal]);
  
  // Add color selection modal
  const renderColorSelectionModal = () => {
    if (!showColorModal) return null;

    return (
      <div className="color-modal-overlay">
        <div className="color-modal">
          <h3>Choose your color</h3>
          <div className="color-options">
            {predefinedColors.map(color => (
              <div
                key={color}
                className="color-option"
                style={{ backgroundColor: color }}
                onClick={async () => {
                  await saveUserColor(user.uid, color);
                  setUserColors(prev => ({ ...prev, [user.uid]: color }));
                  setShowColorModal(false);
                  loadAllUsersData();
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const reorderCourses = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
  
    const reorderedCourses = Array.from(courses);
    const [movedItem] = reorderedCourses.splice(result.source.index, 1);
    reorderedCourses.splice(result.destination.index, 0, movedItem);
  
    // Update courses state
    setCourses(reorderedCourses);
  
    // Calculate new order
    const newOrder = reorderedCourses.reduce((acc, course, index) => {
      acc[course.id] = index;
      return acc;
    }, {});
  
    // Update personal order
    setPersonalOrder(newOrder);
    
    // Save to database
    await savePersonalOrder(newOrder);
    
    // Update all users data to reflect changes
    const updatedAllUsersData = allUsersData.map(userData => {
      if (userData.userId === user.uid) {
        return {
          ...userData,
          courses: reorderedCourses
        };
      }
      return userData;
    });
    
    setAllUsersData(updatedAllUsersData);
    
    // Save changes to Firestore
    await setDoc(doc(db, 'studyTracker', user.uid), {
      courses: reorderedCourses
    });
  };
  
  const sortCoursesByPersonalOrder = (courses) => {
    return [...courses].sort((a, b) => {
      const orderA = personalOrder[a.code] ?? Number.MAX_SAFE_INTEGER;
      const orderB = personalOrder[b.code] ?? Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  };
  
  const handleColorChange = async (userId, newColor) => {
    // Update userColors state
    const updatedColors = { ...userColors, [userId]: newColor };
    setUserColors(updatedColors);

    // Update allUsersData with new color
    const updatedAllUsersData = allUsersData.map(userData => {
      if (userData.userId === userId) {
        return { ...userData, color: newColor };
      }
      return userData;
    });
    setAllUsersData(updatedAllUsersData);

    // Save to Firestore
    await saveUserColor(userId, newColor);
    setShowColorPicker(null);
  };

  const toggleCourse = (courseCode) => {
    setExpandedCourses(prev => ({
      ...prev,
      [courseCode]: !prev[courseCode]
    }));
  };
  const saveUserColor = async (userId, color) => {
    await setDoc(doc(db, 'userColors', userId), { color });
  };

  const loadUserColors = async () => {
    const colorsSnapshot = await getDocs(collection(db, 'userColors'));
    const colors = {};
    colorsSnapshot.forEach(doc => {
      colors[doc.id] = doc.data().color;
    });
    setUserColors(colors);
  };

  const getUserColor = (userId) => {
    if (!userColors[userId]) {
      // Assign a new color from predefined colors or fallback to a random color
      userColors[userId] = predefinedColors[Object.keys(userColors).length % predefinedColors.length];
    }
    return userColors[userId];
  };



  if (!user) {
    return (
      <div className="study-tracker-container">
        <h2 className="study-tracker-title">Study Tracker</h2>
        <div className="login-message">Please log in to use Study Tracker</div>
      </div>
    );
  }

  const generateCourseCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const loadAllUsersData = async () => {
    if (!user) return;

    // Get all users with colors
    const colorsSnapshot = await getDocs(collection(db, 'userColors'));
    const allData = [];

    // Get study data for each user
    for (const colorDoc of colorsSnapshot.docs) {
      const userId = colorDoc.id;
      const userColor = colorDoc.data().color;

      // Get user profile data (displayName)
      const userProfileDoc = await getDoc(doc(db, 'users', userId));
      const userName = userProfileDoc.data()?.displayName || userId;

      // Get study data
      const studyDoc = await getDoc(doc(db, 'studyTracker', userId));
      const courses = studyDoc.exists() ? studyDoc.data().courses || [] : [];

      allData.push({
        userId,
        userName,
        courses,
        color: userColor
      });
    }

    setAllUsersData(allData);
  };


  const loadUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, 'studyTracker', userId));
      if (userDoc.exists()) {
        setCourses(userDoc.data().courses || []);
      }
    } catch (error) {
      console.error("Error loading user data:", error);
    }
  };

  const saveUserData = async (userId, courses) => {
    try {
      console.log("Saving user data:", courses); // Debugging line
      await setDoc(doc(db, 'studyTracker', userId), { courses });
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  const addCourse = async () => {
    if (newCourseName && newCourseLectures) {
      let totalLectures = newCourseLectures;

      if (newCourseLectures.includes('*')) {
        const [base, repeat] = newCourseLectures.split('*').map(num => parseInt(num.trim()));
        totalLectures = base * repeat;
      }

      const courseCode = generateCourseCode();
      const newCourse = {
        name: newCourseName,
        lectures: Number(totalLectures),
        progress: [],
        code: courseCode,
        creator: user.uid,
        format: newCourseLectures.includes('*') ? newCourseLectures : null
      };

      const updatedCourses = [...courses, newCourse];

      // Update local state immediately
      setCourses(updatedCourses);

      // Update allUsersData immediately
      setAllUsersData(prev => prev.map(userData =>
        userData.userId === user.uid
          ? { ...userData, courses: updatedCourses }
          : userData
      ));

      // Save to database
      await saveUserData(user.uid, updatedCourses);

      setNewCourseName('');
      setNewCourseLectures('');
    }
  };


  const getLectureLabel = (index, course) => {
    if (course.format && course.format.includes('*')) {
      const [base, repeat] = course.format.split('*').map(num => parseInt(num.trim()));
      const chapter = Math.floor(index / repeat) + 1;
      const subSection = (index % repeat) + 1;
      return `${chapter}-${subSection}`;
    }
    return (index + 1).toString();
  };
  const deleteCourse = (courseIndex) => {
    const courseToDelete = courses[courseIndex];
    if (window.confirm('Are you sure you want to delete this course?')) {

    // Check if the course creator is the current user
    if (courseToDelete && courseToDelete.creator === user.uid) {
      const updatedCourses = [...courses];
      updatedCourses.splice(courseIndex, 1);
      setCourses(updatedCourses); // Update local state immediately
      saveUserData(user.uid, updatedCourses); // Save the updated data to Firestore

      // Update the global `allUsersData` to keep it in sync if it's displayed
      if (showAllUsers) {
        const updatedAllUsersData = allUsersData.map(userData => {
          if (userData.userId === user.uid) {
            return { ...userData, courses: updatedCourses };
          }
          return userData;
        });
        setAllUsersData(updatedAllUsersData); // Update the all users' data state
      }
    } else {
      console.error("You do not have permission to delete this course.");
    }
  }
  };
  const handleMouseDown = (lectureIndex) => {
    setDragging(true);
    setDragStart(lectureIndex);
    setDragEnd(lectureIndex);
  };

  const handleMouseEnter = (lectureIndex) => {
    if (dragging) {
      setDragEnd(lectureIndex);
    }
  };

  const handleMouseUp = (courseCode) => {
    if (dragging && dragStart !== null && dragEnd !== null) {
      const start = Math.min(dragStart, dragEnd) + 1;
      const end = Math.max(dragStart, dragEnd) + 1;
      updateProgress(courseCode, start, end);
    }
    setDragging(false);
    setDragStart(null);
    setDragEnd(null);
  };

  const groupProgressByDate = (progress) => {
    const grouped = {};
    progress.forEach(entry => {
      if (!grouped[entry.date]) {
        grouped[entry.date] = [];
      }
      grouped[entry.date].push(entry);
    });
    return grouped;
  };

  // Update the updateProgress function to include UUID
  const updateProgress = async (courseCode, start, end) => {
    const today = new Date();
    const formattedDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;

    const newProgress = {
      id: uuidv4(), // Add unique ID
      date: formattedDate,
      range: `${start}-${end}`,
      updater: user.uid
    };

    const updatedAllUsersData = allUsersData.map(userData => {
      const userCourses = userData.courses.map(course => {
        if (course.code === courseCode) {
          return {
            ...course,
            progress: [...course.progress, newProgress]
          };
        }
        return course;
      });
      return { ...userData, courses: userCourses };
    });

    setAllUsersData(updatedAllUsersData);

    const courseOwner = updatedAllUsersData.find(userData =>
      userData.courses.some(course => course.code === courseCode)
    );

    if (courseOwner) {
      await setDoc(doc(db, 'studyTracker', courseOwner.userId), {
        courses: courseOwner.courses
      });
    }
  };

  const renderColorLegend = () => (
    <div className="color-legend">
      {allUsersData.map(userData => (
        <div key={userData.userId} className="color-legend-item">
          <div
            className="color-box"
            style={{ backgroundColor: userData.color }}
            onClick={() => userData.userId === user.uid && setShowColorPicker(userData.userId)}
          />
          <span>{userData.userId === user.uid ? 'You' : userData.userName}</span>
          {showColorPicker === userData.userId && (
            <div className="color-picker-popup">
              <div className="predefined-colors">
                {predefinedColors.map(color => (
                  <div
                    key={color}
                    className="color-option"
                    style={{
                      backgroundColor: color,
                      border: tempColor === color ? '2px solid #000' : 'none'
                    }}
                    onClick={() => setTempColor(color)}
                  />
                ))}
              </div>
              <ChromePicker
                color={tempColor || userData.color}
                onChange={(color) => setTempColor(color.hex)}
              />
              <button
                className="color-confirm-button"
                onClick={() => {
                  if (tempColor) {
                    handleColorChange(userData.userId, tempColor);
                    setTempColor(null);
                    setShowColorPicker(null);
                  }
                }}
              >
                Confirm Color
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
  const deleteProgress = async (courseIndex, progressId) => {
    if (window.confirm('Are you sure you want to delete this progress?')) {

    // 모든 사용자의 데이터를 순회하면서 해당 progress 찾기
    const updatedAllUsersData = allUsersData.map(userData => {
      const updatedCourses = userData.courses.map(course => {
        // 해당 progress가 있는 course 찾기
        const hasProgress = course.progress.some(p => p.id === progressId);
        if (hasProgress) {
          return {
            ...course,
            progress: course.progress.filter(p => p.id !== progressId)
          };
        }
        return course;
      });
      return {
        ...userData,
        courses: updatedCourses
      };
    });

    // 상태 업데이트
    setAllUsersData(updatedAllUsersData);

    // 해당 course의 소유자 찾기
    const courseOwner = updatedAllUsersData.find(userData =>
      userData.courses.some(course =>
        course.progress.some(p => p.id === progressId)
      )
    );

    // Firestore 업데이트
    for (const userData of updatedAllUsersData) {
      await setDoc(doc(db, 'studyTracker', userData.userId), {
        courses: userData.courses
      });
    }

    // 현재 사용자의 로컬 courses 상태 업데이트
    const currentUserData = updatedAllUsersData.find(data => data.userId === user.uid);
    if (currentUserData) {
      setCourses(currentUserData.courses);
    }
  }
  };







  const renderLectureBlocks = (lectures, courseIndex, course) => {
    const blocks = [];
    const batchSize = 42;
    const shouldReduceSize = lectures > 42;
    const blockWidth = shouldReduceSize ? 20 : 25;
    const gap = shouldReduceSize ? 1 : 1;

    for (let i = 0; i < lectures; i += batchSize) {
      const batchStart = i + 1;
      const batchEnd = Math.min(i + batchSize, lectures);
      blocks.push(
        <div key={i}>
          <div className="lecture-batch">
            {Array.from({ length: Math.min(batchSize, lectures - i) }, (_, j) => (
              <div
  key={i + j}
  className={`lecture-block ${selectionStart === (i + j) ? 'selected' : ''}`}
  onClick={() => handleBlockClick(course.code, i + j)}
  style={{ width: `${blockWidth}px`, height: `20px` }}
>
                <span className="lecture-number" style={{
                  fontSize: course.format?.includes('*')
                    ? (shouldReduceSize ? '8px' : '9px')  // 2px smaller for formatted numbers
                    : (shouldReduceSize ? '9px' : '10px')  // original size for regular numbers
                }}>
                  {getLectureLabel(i + j, course)}
                </span>
              </div>
            ))}
          </div>

          <div className="progress-log">
            {Object.entries(groupProgressByDate(course.progress
              .filter(entry => {
                const [start] = entry.range.split('-').map(num => parseInt(num));
                return start >= batchStart && start <= batchEnd;
              })))
              .map(([date, entries]) => {
                const [month, day] = date.split('/');
                return (
                  <div key={date} className="progress-date-group">
                    <span className="progress-date">{`[${month}/${day}]`}</span>
                    {entries.map((entry, idx) => {
                      const [start, end] = entry.range.split('-').map(num => parseInt(num, 10) - 1);
                      const updaterColor = getUserColor(entry.updater);
                      return (
                        <div key={idx} className="progress-entry">
                          <span>{entry.range}</span>
                          <div className="progress-indicator"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProgressClick(entry);
                            }}
                            style={{
                              left: `${(shouldReduceSize ? 20 : 20) + (start % batchSize) * (blockWidth + gap + (shouldReduceSize ? 3 : 3))}px`,
                              width: `${(shouldReduceSize ? 0 : 0) + (end - start + 1) * (blockWidth + gap + (shouldReduceSize ? 3 : 3))}px`,
                              backgroundColor: updaterColor
                            }}>
                          </div>
                          <button
  className="delete-button"
  onClick={(e) => {
    e.stopPropagation();
    deleteProgress(courseIndex, entry.id);
  }}
  style={{ 
    display: entry.updater === user.uid ? 'inline' : 'none',
    pointerEvents: 'auto'  // Add this line
  }}
>
  X
</button>

                        </div>

                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      );
    }
    return blocks;
  };


  return (
    
    <div className="study-tracker-container">
      {renderColorSelectionModal()}

      <div className="study-tracker-header">
  <div className="title-dday-container">
    <h2 className="study-tracker-title">Study Tracker</h2>
    <div className="dday-section">
  {dDay ? (
    <div className="dday-display">{timeLeft}</div>
  ) : (
    <div className="dday-input-group">
      <input
        type="date"
        onChange={(e) => setTempDate(e.target.value)}
        className="dday-input"
      />
      <button 
        className="confirm-dday"
        onClick={() => tempDate && saveDDay(tempDate)}
      >
        Set D-day
      </button>
    </div>
  )}
  {dDay && (
    <button 
      className="reset-dday"
      onClick={() => {
        setDDay(null);
        setTimeLeft(null);
        setTempDate('');
        setDoc(doc(db, 'dDay', user.uid), {});
      }}
    >
      Reset
    </button>
  )}
</div>
  </div>
</div>

      <div className="input-button-container">
        <div className="study-tracker-inputs">
          <div className="create-course-section">
            <input
              type="text"
              placeholder="Course Name"
              value={newCourseName}
              onChange={(e) => setNewCourseName(e.target.value)}
              className="study-tracker-input"
            />
            <input
              type="text"
              placeholder="Total Lectures"
              value={newCourseLectures}
              onChange={(e) => setNewCourseLectures(e.target.value)}
              className="study-tracker-input"
            />
            <button onClick={addCourse} className="study-tracker-button">Add Course</button>
          </div>
        </div>
        <button
          className="view-all-button"
          onClick={() => {
            setShowAllUsers(!showAllUsers);
            if (showAllUsers) loadAllUsersData();
          }}
        >
          {showAllUsers ? 'Show My Progress Only' : 'View All Users Progress'}
        </button>
      </div>
      {renderColorLegend()}
      {showMemoModal && (
  <MemoModal
    progress={selectedProgress}
    onClose={() => setShowMemoModal(false)}
    onSave={saveMemo}
    onDelete={deleteMemo}
  />
)}
<DragDropContext onDragEnd={onDragEnd}>
  <Droppable droppableId="courses">
    {(provided) => (
      <div
        {...provided.droppableProps}
        ref={provided.innerRef}
        className="study-tracker-courses"
      >
        {(showAllUsers ? allUsersData : [{
          userId: user.uid,
          courses,
          color: userColors[user.uid] || predefinedColors[0]
        }]).map(userData => (
          <div key={userData.userId}>
            {userData.courses && sortCoursesByPersonalOrder(userData.courses).map((course, index) => (
              <Draggable
                key={course.code}
                draggableId={course.code}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="study-tracker-course"
                  >
                    <div 
                      className="course-header"
                      {...provided.dragHandleProps}
                    >
                      <h3 className="course-title" style={{ color: userData.color }}>
                        <span onClick={() => toggleCourse(course.code)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontSize: '12px', marginRight: '5px', color: '#ced4da' }}>
                              {expandedCourses[course.code] ? '▼' : '▶'}
                            </span>
                            {course.name}
                          </div>
                          {userData.userId === user.uid && (
                            <button className="delete-button2" onClick={() => deleteCourse(index)} style={{ color: userData.color }}>X</button>
                          )}
                        </span>
                      </h3>
                    </div>
                    {expandedCourses[course.code] && (
                      <div className="lecture-bar">
                        {renderLectureBlocks(course.lectures, index, course, userData.color)}
                      </div>
                    )}
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
</DragDropContext>


    </div>
  );
};

export default StudyTracker;
